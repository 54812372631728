import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {mapState} from 'vuex'
import moment from 'moment'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrStorage from '@/modules/common/services/storage.service'
import MediaService from '@/modules/common/services/media.service'
import Notification from '@/modules/common/services/notification.service'
import * as ics from 'ics'
import GtrSessionField from '@/modules/registration/views/components/gtr-session-field/gtr-session-field.vue'
import {
  Session,
  ParticipantData
} from '@/modules/registration/interfaces/index.interface'

@Component({
  name: 'GtrRegistrationRegisterView',
  computed: {
    ...mapState('formbuilder', ['form', 'finishedPayment']),
    ...mapState('auth', ['viewable_reg_pages', 'current_reg_pages', 'needAuthField']),
    ...mapState('payment', ['fees', 'groupFees', 'payflow_token', 'stripe_token', 'payment_form', 'participantTracker', 'subtotal', 'taxesTotal', 'totalPromoCodeDiscount', 'transactionTotal', 'balance']),
    ...mapState('event', ['event'])
  },
  components: {
    'gtr-session-field': GtrSessionField,
    StripeCard: () => import('@/modules/common/components/stripe/stripe-card/stripe-card.vue')
  }
})
export default class GtrRegistrationRegisterView extends GtrSuper {
  @Prop()
  allContent: any

  @Prop()
  design: any

  @Prop()
  template_data: any

  @Prop()
  template_name: any

  @Prop()
  settings: any

  @Prop()
  option_groups: any

  @Prop()
  tiers: any

  @Prop()
  content_pages: any

  @Prop()
  event_uuid: any

  @Prop()
  participant: any

  // Vuex bindings.
  participantTracker: any

  // subtotal: any;

  // taxesTotal: any;

  // totalPromoCodeDiscount: any;

  // transactionTotal: any;

  private sessionSelections = []

  // balance: any;
  fees!: Record<string, any>
  groupFees!: Record<string, any>

  expanded: Array<ParticipantData> = []
  nonce = 0

  get selectedSessions () {
    return this.sessionSelections
  }

  set selectedSessions (value) {
    this.sessionSelections = value
  }

  get sessions (): Session[] {
    return (this.option_groups as Array<any>)?.find(group => group.name === 'Sessions')?.options ?? []
  }

  get designSettings() {
    return {
      pageCountColor: {
        primary: this.design?.default_page_counter_color ? this.design.default_page_counter_color : '#00333A'
      },
      pageCountTextColor: {
        primary: this.design?.default_page_counter_text_color ? this.design?.default_page_counter_text_color : '#ffffff'
      },
      buttonColors: {
        primary: this.design?.default_primary_button_color ? this.design.default_primary_button_color : '',
        secondary: this.design?.default_secondary_button_color ? this.design.default_secondary_button_color : '#FFDEDE'
      },
      buttonTextColor: {
        primary: this.design?.default_primary_button_text_color ? this.design?.default_primary_button_text_color : '#ffffff',
        secondary: this.design?.default_secondary_button_text_color ? this.design?.default_secondary_button_text_color : '#803140'
      },
      accentColor: {
        primary: this.design?.default_accent_body_text_color ? this.design?.default_accent_body_text_color : '#00333A'
      }
    }
  }

  get questionLabelFormatting () {
    const formatting = {}
    // if bold in default_question_label_formatting add fontWeight: 'bold' to formating object. if italic add fontStyle: 'italic', if underline add textDecoration: 'underline' otherwise return empty object.
    if (this.design.default_question_label_formatting) {
      if (this.design.default_question_label_formatting.includes('bold')) {
        formatting['fontWeight'] = 'bold'
      }
      if (this.design.default_question_label_formatting.includes('italics')) {
        formatting['fontStyle'] = 'italic'
      }
      if (this.design.default_question_label_formatting.includes('underline')) {
        formatting['textDecoration'] = 'underline'
      }
    } else {
      return {}
    }
    return formatting
  }

  //#region Computed Props
  get optionGroupUuid() {
    for (const option_group_index in this.option_groups) {
      const option_group = this.option_groups[option_group_index]
      if (option_group.name == 'Registration Types') {
        return option_group.uuid
      }
    }
    return null
  }

  get regTypes() {
    const regTypes: any = {_default: 'Default', Default: 'Default'}
    for (const option_group_index in this.option_groups) {
      const option_group = this.option_groups[option_group_index]
      if (option_group.name == 'Registration Types') {
        for (const reg_type_index in option_group.options) {
          const reg_type = option_group.options[reg_type_index]
          regTypes[reg_type.uuid] = reg_type.name
        }
        break
      }
    }
    return regTypes
  }

  get parentParticipant() {
    if (this.$data._groupFees && this.$data._groupFees.group_all) {
      for (const index in this.$data._groupFees.group_all) {
        // set up the object.
        if (this.$data._groupFees.group_all[index].participant_group?.parent) {
          return this.$data._groupFees.group_all[index]
        }
      }
    }
    return null
  }

  showForNonParent(field) {
    if (field?.show_only_parent && this.$data._groupFees && this.$data._groupFees.group_all && field) {
      const onParent = this.parentParticipant && this.parentParticipant.uuid === this.participant.uuid
      return onParent
    }
    return true
  }

  get groupRegData() {
    const data: Array<ParticipantData> = []

    if (this.$data._groupFees.group_all) {
      //treat as group
      for (const index in this.$data._groupFees.group_all) {
        // set up the object.
        const uuid = this.$data._groupFees.group_all[index].uuid
        const name = this.$data._groupFees.group_participant_names[uuid]
        const registration_type = this.$data._groupFees.group_all[index].registration_type ?? 'Default'
        const email = this.$data._groupFees.group_all[index].participant_data.email
        const line_items = this.$data._groupFees.group_participant_fees[uuid].line_items
        const parent = this.$data._groupFees.group_all[index].participant_group.parent
        const participantData: ParticipantData = {
          id: uuid,
          participant: name,
          registration_type: registration_type,
          email: email,
          items: line_items,
          subtotal: this.$data._groupFees.group_participant_fees[uuid].subtotal,
          subtotal_tax: this.$data._groupFees.group_participant_fees[uuid].subtotal_tax,
          promoCode: this.$data._groupFees.group_participant_fees[uuid].promoCode,
          discount_total: this.$data._groupFees.group_participant_fees[uuid].discount_total,
          total: this.$data._groupFees.group_participant_fees[uuid].total,
          parent,
          full_participant: this.$data._groupFees.group_all[index]
        }
        data.push(participantData)
        this.$store.commit('payment/UPDATE_PARTICIPANT_TRACKER',
          {
            uuid: uuid, // paticipants uuid.
            name: name, // participants name.
            line_items: this.$data._groupFees.group_participant_fees[uuid].line_items, // add new line_items.
            parent,// use the current objects parent value.
            full_participant: this.$data._groupFees.group_all[index]
          })
      }
    } else {
      //treat as single participant
      const participants = this.participantTracker
      for (const uuid in participants) {
        // set up the object.
        const participantData: ParticipantData = {
          id: uuid,
          participant: participants[uuid].name,
          items: participants[uuid].lineItems,
          total: 0,
          parent: participants[uuid].parent,
          full_participant: participants[uuid]
        }

        const lineItemLength = participantData.items.length
        if (lineItemLength > 0) {
          // now update the total.
          participantData.total = participantData.items.reduce((previousValue: Record<string, number>, currentValue: Record<string, any>) => ({total: previousValue.total + currentValue.total})).total
        }
        data.push(participantData)
      }
    }

    data.forEach((participant: ParticipantData) => {
      this.$set(this.$data.participantLineItemModalsOpen, participant.id, false)
    })

    return data
  }

  /** Used in group reg --Your Group-- for display purposes only */
  get firstNameInput () {
    let firstName = ''
    for (let i = 0; i < this.$data.page?.pageData?.fields?.length; i++) {
      const field = this.$data.page.pageData.fields[i]
      if (field.field === 'first_name') {
        firstName = field.current_value
      }
    }
    return firstName
  }

  /** Used in group reg --Your Group-- for display purposes only */
  get lastNameInput () {
    let lastName = ''
    for (let i = 0; i < this.$data.page?.pageData?.fields?.length; i++) {
      const field = this.$data.page.pageData.fields[i]
      if (field.field === 'last_name') {
        lastName = field.current_value
      }
    }
    return lastName
  }

  get event () {
    return this.allContent?.data?.event ?? {}
  }

  get event_name() {
    if (this.allContent && this.allContent.data && this.allContent.data.event) {
      return this.allContent.data.event.name
    }
  }

  get paymentProcessorCurrency () {
    return this.allContent?.data?.payment_processor_currency ?? 'usd'
  }

  get sidebar_pages() {
    const form_pages: any = []
    const viewable_form_pages: any = []
    if (this.allContent && this.allContent.data && this.allContent.data.form && this.$data._form && this.$data._form.current_reg_pages) {
      for (let i = 0; i < this.allContent.data.form.length; i++) {
        form_pages.push({
          name: this.allContent.data.form[i].settings.name,
          page_type: this.allContent.data.form[i].page_type,
          index: i
        })
      }
      for (let i = 0; i < form_pages.length; i++) {
        if (this.$data._form.current_reg_pages.includes(form_pages[i].index) || form_pages[i].page_type === 'RECEIPT') {
          viewable_form_pages.push(form_pages[i].name)
        }
      }
    }
    return viewable_form_pages
  }

  get getPositionFromSidebarPages() {
    return this.$data._form.viewable_reg_pages.findIndex((page: any) => page === this.$data.pagenum) + 1
  }

  get tracking_code_stripped() {
    const script_list: any = []
    let tracking_code = this.settings.tracking_code
    if (tracking_code) {
      const parser = new DOMParser()
      tracking_code = parser.parseFromString(tracking_code, 'text/html')
      tracking_code = tracking_code.querySelectorAll('script')
      tracking_code.forEach(script => {
        script_list.push({
          id: 'script' + Math.random(),
          src: script.src.toString(),
          js: script.innerHTML.toString()
        })
      })
    }
    return script_list
  }

  get custom_js_stripped() {
    const script_list: any = []
    let custom_js = this.settings.custom_js
    if (custom_js) {
      const parser = new DOMParser()
      custom_js = parser.parseFromString(custom_js, 'text/html')
      custom_js = custom_js.querySelectorAll('script')
      custom_js.forEach(script => {
        script_list.push({
          id: 'script' + Math.random(),
          src: script.src.toString(),
          js: script.innerHTML.toString()
        })
      })
    }
    return script_list
  }

  //#endregion

  get participantAndFormLoaded() {
    /**
     * Hack to wait for 2 fields to load
     */
    return this.$data.page && this.participant
  }

  get stripe_intent() {
    if (this.$data._stripe_token && this.$data._stripe_token.tokenData && this.$data._stripe_token.tokenData.stripeIntentSecret) {
      return this.$data._stripe_token.tokenData.stripeIntentSecret
    }
    return null
  }

  get formLoaded() {
    return this.$data.page
  }

  get payflow_iframe_url() {
    return this.$data._payflow_token.tokenData ? this.$data._payflow_token.tokenData.tokenData.iframeUrl : ''
  }

  get groupTotalPromoCodeDiscount() {
    let total = 0
    if (Object.keys(this.group_line_items).length > 0) {
      for (const uuid in this.group_line_items) {
        total += this.group_line_items[uuid].total_discount
          ? this.group_line_items[uuid].total_discount
          : 0
      }
    }
    return total
  }

  get status() {
    return this.participant.participant_data.status
  }

  get group_line_items() {
    const filteredGroupFees: any = {}

    const noGroupFees = Object.keys(this.$data._groupFees).length === 0
    const lineItems: Array<Record<string, any>> = noGroupFees ? this.$data._fees.line_items : this.$data._groupFees.line_items

    if (lineItems) {
      for (const item of lineItems) {
        const uuid = item.participant_uuid
        filteredGroupFees[uuid] ? filteredGroupFees[uuid].push(item) : filteredGroupFees[uuid] = [item]
      }
    }

    return filteredGroupFees
  }

  get promocode(): boolean {
    return (this.$data._fees.discount_total > 0 || this.$data._groupFees.discount_total > 0)
  }

  get promocodeDiscount(): number {
    return this.$data._fees.discount_total ? this.$data._fees.discount_total : this.$data._groupFees.discount_total
  }

  get promocodeDiscountPercent(): number {
    return ((this.$data._fees.discount_total * 100) / this.$data._fees.subtotal)
  }

  get promocodeDiscountPercentGroup(): number {
    return ((this.$data._groupFees.discount_total * 100) / this.$data._groupFees.subtotal)
  }

  get group_participant_names() {
    return this.$data._groupFees.group_participant_names
  }

  //#endregion

  get isGroup() {
    return this.participant.participant_group_uuid
  }

  get isThisAPaymentPage() {
    return this.$data._form.pageData ? this.$data._form.pageData.hasOwnProperty('paymentForm') : false
  }

  get isPayForAnotherPage() {
    if (this.$data._form.pageData && this.$data._form.pageData.hasOwnProperty('fields')) {
      for (const fieldIndex in this.$data._form.pageData.fields) {
        const field = this.$data._form.pageData.fields[fieldIndex]
        if (field.hasOwnProperty('field')) {
          if (field.field === 'pay_for_another') {
            return true
          }
        }
      }
    }
    return false
  }

  get paymentWidget() {
    return this.$data._form.pageData ? this.$data._form.pageData.paymentForm.widget_id : false
  }

  get payment_processor_information() {
    return this.$data._form.pageData.paymentForm
  }

  get expirationYearsItems() {
    const arr: any = []
    /**
     * Fill the expirationYearsArray with this year and 15 years in the future
     * Must be a string for payment processing
     */
    let currentYear = parseInt(moment().year().toString())
    arr.push(currentYear.toString())
    for (let i = 0; i <= 15; i++) {
      arr.push((currentYear++).toString())
    }
    return arr
  }

  /**
   * TODO: Flag for deletion
   *
   * Another implementation for line items is in place.
   * Leaving in place for now.
   */
  get line_items() {
    return this.$data._fees.line_items
  }

  /* This is not used yet. TODO: Remove line items of deleted participants; unset group when all but one participants are deleted.
  async deleteParticipantFromGroup (participantUuid: string, confirm: boolean) {
      try {
          if (confirm) {
              this.$data.loading = true
              // await this.$store.dispatch('common/showLoader', { value: true })
              const payload = {
                  event_uuid: this.event_uuid,
                  group_uuid: this.participantAndFormLoaded?.participant_group_uuid,
                  participant_uuid: participantUuid
              }
              const response = await this.$store.dispatch('register/deleteParticipantFromGroup', payload)
              Container.get(Notification).success('Participant successfully deleted.')
              this.$router.go(0) // TODO: update reactively instead of reloading the page.
          }
      } catch (error) {
          Container.get(ErrorHandlerService).error(error)
      } finally {
          // await this.$store.dispatch('common/hideLoader')
          this.$data.loading = false
      }
  } */

  get currencySymbol() {
    return this.$data._fees.currency
  }

  /**
   * TODO: Flag for deletion. I don't think this gets used anywhere anymore.
   * Leaving in place for now.
   */
  get group_total() {
    return this.$data._groupFees.total
  }

  /**
   * TODO: Flag for deletion. I don't think this gets used anywhere anymore.
   * Leaving in place for now.
   */
  get group_transaction_total() {
    return this.$data._groupFees.transaction_total
  }

  /**
   * TODO: Flag for deletion. I don't think this gets used anywhere anymore.
   * Leaving in place for now.
   */
  get group_balance() {
    return this.$data._groupFees.balance
  }

  /**
   * TODO: Flag for deletion. I don't think this gets used anywhere anymore.
   * Leaving in place for now.
   */
  get discount_total() {
    return this.$data._fees.discount_total
  }

  /**
   * TODO: Flag for deletion. I don't think this gets used anywhere anymore.
   * Leaving in place for now.
   */
  get group_discount_total() {
    return this.$data._groupFees.discount_total
  }

  /**
   * TODO: Flag for deletion. I don't think this gets used anywhere anymore.
   * Leaving in place for now.
   */
  get group_subtotal() {
    return this.$data._groupFees.subtotal
  }

  get taxesTotal() {
    const amountOfParticipant = Object.keys(this.participantTracker).length
    return amountOfParticipant === 1 ? this.fees.subtotal_tax : this.groupFees.subtotal_tax
  }

  get taxesLabel() {
    return (this.settings?.tax_type?.en || this.settings?.tax_type) ?? 'Taxes'
  }

  get feesTotal() {
    const amountOfParticipant = Object.keys(this.participantTracker).length
    return amountOfParticipant === 1 ? this.fees.subtotal : this.groupFees.subtotal
  }

  get discountTotal() {
    const amountOfParticipant = Object.keys(this.participantTracker).length
    return amountOfParticipant === 1 ? this.fees.discount_total : this.groupFees.discount_total
  }

  get discountPercentage() {
    const amountOfParticipant = Object.keys(this.participantTracker).length
    return amountOfParticipant === 1 ? this.promocodeDiscountPercent : this.promocodeDiscountPercentGroup
  }

  get txnTotal() {
    const amountOfParticipant = Object.keys(this.participantTracker).length
    return amountOfParticipant === 1 ? this.fees.total : this.groupFees.total
  }

  get balance() {
    const amountOfParticipant = Object.keys(this.participantTracker).length
    return amountOfParticipant === 1 ? this.fees.balance : this.groupFees.balance
  }

  /**
   * TODO: Flag for deletion. I don't think this gets used anywhere anymore.
   * Leaving in place for now.
   */
  get group_taxes_total() {
    return this.$data._groupFees.taxes_total
  }

  get next_button_text() {
    return this.design.default_next_button_text && this.design.default_next_button_text[this.$data.currentLanguage]
      ? this.design.default_next_button_text[this.$data.currentLanguage]
      : 'Next'
  }

  get previous_button_text() {
    return this.design.default_previous_button_text && this.design.default_previous_button_text[this.$data.currentLanguage]
      ? this.design.default_previous_button_text[this.$data.currentLanguage]
      : 'Back'
  }

  get cancel_button_text() {
    return this.design.default_cancel_registration_button_text &&
    this.design.default_cancel_registration_button_text[this.$data.currentLanguage]
      ? this.design.default_cancel_registration_button_text[this.$data.currentLanguage]
      : 'Cancel Registration'
  }

  get transfer_button_text() {
    return this.design.default_transfer_registration_button_text &&
    this.design.default_transfer_registration_button_text[this.$data.currentLanguage]
      ? this.design.default_transfer_registration_button_text[this.$data.currentLanguage]
      : 'Transfer Registration'
  }

  get save_and_finish_button_text() {
    return this.design.default_save_and_finish_button_text &&
    this.design.default_save_and_finish_button_text[this.$data.currentLanguage]
      ? this.design.default_save_and_finish_button_text[this.$data.currentLanguage]
      : 'Save & Finish'
  }

  get editing_disabled() {
    return this.settings.editing_disabled && this.participant && this.participant?.participant_data.status.toLowerCase() === 'complete'
  }

  get editing_disabled_verbiage() {
    return this.settings.editing_disabled_verbiage['en'] ? this.settings.editing_disabled_verbiage['en'] : 'Editing is disabled for this site'
  }

  get last_viewable_reg_page() {
    const receipt_index = this.$data._viewable_reg_pages.indexOf('receipt')
    return parseInt(this.$data._viewable_reg_pages[receipt_index - 1])
  }

  get viewable_pages() {
    return this.$data._viewable_reg_pages
  }

  get current_registration_type() {
    return this.participant.registration_type ? this.participant.registration_type : '_default'
  }

  get pageNum() {
    return parseInt(this.$route.params.pagenum)
  }

  get isLastPage() {
    if (this.$data._form && this.$data._form.current_reg_pages) {
      return this.$data._form !== null ? this.$data._form.current_reg_pages[this.$data._form.current_reg_pages.length - 2] == parseInt(this.$route.params.pagenum) : false
    }
    return false
  }

  // from all participants in the participant dictionary.
  get trackerLineItems() {
    let items: Record<string, any>[] = []

    for (const uuid in this.participantTracker) {
      items = [...items, ...this.participantTracker[uuid].lineItems]
    }

    return items
  }

  data() {
    return {
      isExhibitorGroupReg: false,
      isPartOfGroup: false,
      configureStripeCalled: false,
      showOptionPopupModal: false,
      currentOptionPopupDetails: {
        title: '',
        body: ''
      },
      optionGroupFields: null,
      paymentField: null,
      signedResponse: null,
      transaction_type: null,
      loading: false,
      stripeLoaded: false,
      stripe: false,
      elements: false,
      card: false,
      saveAndFinishClicked: false,
      promoCodeProcessDialog: false,
      formProcessDialog: false,
      paymentDialog: false,
      transferDialog: false,
      transferFirst: '',
      transferLast: '',
      transferEmail: '',
      cancelDialog: false,
      participantToken: '',
      operatorFunctions: {
        '=': (a: any, b: any) => {
          if (typeof a === 'object' && typeof b === 'object') {
            // get option group of field.
            const fieldOptionGroupOptions = this.option_groups.filter((group: any) => {
              return group.uuid == a.optionGroup
            })[0].options
            const showIfGroupOptions = this.option_groups.filter((group: any) => {
              return group.uuid == b.optionGroup
            })[0].options

            // get option value;
            const fieldOptionValue = fieldOptionGroupOptions.filter((option: any) => {
              return option.uuid == a.value
            })
            const showIfOptionValue = showIfGroupOptions.filter((option: any) => {
              return option.uuid == b.value
            })
            // if no value match, default to zero. Otherwise use the value given.
            const aValue = fieldOptionValue.length === 0 ? 0 : fieldOptionValue[0].name
            const bValue = showIfOptionValue.length === 0 ? 0 : showIfOptionValue[0].name

            return aValue === bValue
          } else if (typeof a === 'string' && typeof b === 'string') {
            return a === b
          }
        },
        '!=': (a: any, b: any) => a !== b,
        '>': (a: any, b: any) => parseInt(a) > parseInt(b),
        '<': (a: any, b: any) => parseInt(a) < parseInt(b),
        '>=': (a: any, b: any) => {
          if (typeof a == 'object' && typeof b == 'object') {

            // get option group of field.
            const fieldOptionGroupOptions = this.option_groups.filter((group: any) => {
              return group.uuid == a.optionGroup
            })[0].options
            const showIfGroupOptions = this.option_groups.filter((group: any) => {
              return group.uuid == b.optionGroup
            })[0].options

            // get option value;
            const fieldOptionValue = fieldOptionGroupOptions.filter((option: any) => {
              return option.uuid == a.value
            })
            const showIfOptionValue = showIfGroupOptions.filter((option: any) => {
              return option.uuid == b.value
            })

            // if no value match, default to zero. Otherwise use the value given.
            const aValue = fieldOptionValue.length === 0 ? 0 : fieldOptionValue[0].name
            const bValue = showIfOptionValue.length === 0 ? 0 : showIfOptionValue[0].name

            // compare and return.
            return parseInt(aValue) >= parseInt(bValue)
          } else {
            return parseInt(a) >= parseInt(b)
          }
        },
        '<=': (a: any, b: any) => parseInt(a) <= parseInt(b),
        '^=': (a: any, b: any) => (a || '').includes(b),
        '!^=': (a: any, b: any) => !(a || '').includes(b),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        '=null': (a: any, b = null) => a === null || a === '',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        '!=null': (a: any, b = null) => a !== null && a !== ''
      },
      alreadyGotParticipant: false,
      sidebar: false,
      expirationMonthsItems: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      // expirationYearsItems: [],
      page: {},
      event_identifier: this.$route.params.event_identifier,
      selectOptions: [
        {
          name: '',
          value: ''
        },
        {
          name: 'test1',
          value: 'Test 1'
        },
        {
          name: 'test2',
          value: 'Test 2'
        }
      ],
      selectedOptions: [],
      componentTypes: {
        text: 'v-text-field',
        dropdown: 'v-select',
        radio: 'v-radio'
      },
      activeField: 0,
      isNext: true,
      pagenum: parseInt(this.$route.params.pagenum),
      login_key: this.$route.params.login_key,
      pageToGoBackTo: 0,
      currentLanguage: 'en',
      showButtons: true,
      selected_options_printable: {},
      currentPageSubmitData: {},
      _stripe_token: null,
      _payflow_token: {},
      _groupFees: {},
      _form: {},
      _fees: {},
      _viewable_reg_pages: [],
      position: this.$store.state.common.page,
      loadingButtonBack: false,
      loadingButtonNext: false,
      loadingStripe: false,
      stripeError: null,
      provinces: [
        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'ON',
        'PE',
        'QC',
        'SK',
        'NT',
        'NU',
        'YT'
      ],
      states: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY'
      ],
      countries: [
        'United States',
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua & Deps',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Central African Rep',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo',
        'Congo {Democratic Rep}',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'East Timor',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland {Republic}',
        'Israel',
        'Italy',
        'Ivory Coast',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Korea North',
        'Korea South',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar, {Burma}',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russian Federation',
        'Rwanda',
        'St Kitts & Nevis',
        'St Lucia',
        'Saint Vincent & the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome & Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad & Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe'
      ],
      zipCode: null,
      allowRegistrationCancel: false,
      loadingInfo: false,
      loadingTable: false,
      calendarICS: null,
      showCalendarButton: false,
      participantLineItemModalsOpen: {},
      showSendAReceiptButton: false,
      showSendAReceiptDialog: false,
      sendReceiptLoading: false
    }
  }

  //#region Lifecicly
  created() {
    this.$data.event_identifier = this.$route.params.event_identifier
    const $_GET: any = {}
    if (document.location.toString().indexOf('?') !== -1) {
      const query = document.location
        .toString()
        // get the query string
        .replace(/^.*?\?/, '')
        // and remove any existing hash string (thanks, @vrijdenker)
        .replace(/#.*$/, '')
        .split('&')

      for (let i = 0, l = query.length; i < l; i++) {
        const aux = decodeURIComponent(query[i]).split('=')
        $_GET[aux[0]] = aux[1]
      }
    }
    if ($_GET['language']) {
      this.$data.currentLanguage = $_GET['language']
    }
    const self = this
    setTimeout(function () {
      self.$store.dispatch('event/sendAnalytics', {event_identifier: self.$data.event_identifier})
    }, 1000)
  }

  updated() {
    this.$nextTick(() => {
      this.shouldSendAReceiptButtonBeVisible()
    })
  }

  isGroupParticipantPaid (participantData: ParticipantData): boolean {
    if (participantData) {
      const amountOwed = this.groupFees?.line_items?.reduce((sum, lineItem) => {
        if (lineItem.participant_uuid === participantData.uuid) {
          sum += lineItem.total
        }
        return sum
      }, 0)
      const amountPaid = this.groupFees?.paid_line_items?.reduce((sum, lineItem) => {
        if (lineItem.participant_uuid === participantData.uuid) {
          sum += lineItem.total
        }
        return sum
      }, 0)

      if (amountPaid === 0 && amountOwed === 0) {
        if (participantData.status === 'Complete') {
          return true
        }
      } else if (amountPaid >= amountOwed) {
        return true
      }
    }

    return false
  }

  shouldSendAReceiptButtonBeVisible() {
    const element = document.getElementById('participantToken') as HTMLInputElement
    if (element && element?.value) {
      this.$data.participantToken = element.value
      this.$data.showSendAReceiptButton = true
    }
  }

  handleOpenSendAReceipt() {
    this.$data.showSendAReceiptDialog = true
  }

  handleCloseSendAReceipt() {
    this.$data.showSendAReceiptDialog = false
  }

  async sendReceipt() {
    try {
      this.$data.sendReceiptLoading = true
      await this.$store.dispatch('event/sendReceipt', {
        event_uuid: this.$data.event_identifier,
        participant_token: this.$data.participantToken
      })
      this.$data.showSendAReceiptDialog = false
      Container.get(Notification).success('Your receipt has been sent.')
    } catch (e) {
      Container.get(Notification).error('There was an error sending your notification. Please try again.')
    } finally {
      this.$data.showSendAReceiptDialog = false
      this.$data.sendReceiptLoading = false
    }
  }

  generateRulesForField(field: any) {
    const rules: any = {}
    if (field.required === '1' || field.required === true) {
      rules.required = true
    }
    const {field: fieldName = ''} = field
    if (fieldName === 'email') {
      rules.email = true
    }
    return rules
  }

  getValidationMode(field) {
    if (['phone_number', 'zip_code'].includes(field.field)) {
      return 'lazy'
    } else {
      return (this as any).eagerTextCustomRule
    }
  }

  async mounted() {
    // first check for login_key
    if (this.$data.login_key) {
      localStorage.removeItem('idp_login')
      // if exists, then login user -- might need to refactor this later
      this.$store.dispatch('common/showLoader', {value: true})
      const payload: any = {
        event_identifier: this.$data.event_identifier,
        pagenum: this.$data.pagenum,
        isDev: this.isDev,
        data: {
          login_key: this.$data.login_key
        }
      }
      for (const field in this.$data.loginFieldData) {
        if (field === 'email') {
          payload.data[field] = this.$data.loginFieldData[field].toLowerCase() // format all email to lowercase
        } else {
          payload.data[field] = this.$data.loginFieldData[field]
        }
      }
      if (this.$data.registration_type && this.$data.registration_type.uuid) {
        payload.data.registrationTypeData = {
          option_group_uuid: this.optionGroupUuid,
          option_uuid: this.$data.registration_type.uuid
        }
      }
      try {
        await this.$store.dispatch('auth/login', payload)
        const pageNumRequested = this.$route.params.pagenum ?? 0
        this.$router.push('/' + (this.isDev ? 'dev/' : '') + payload.event_identifier + '/register/' + pageNumRequested)
      } catch (error) {
        if (error.response.data.error_code === 'AUTH_FIELD_MISMATCH') {
          Container.get(Notification).error(error.response.data.error_message)
          return
        } else if (error.response.data.error_code === 'NEED_AUTH_FIELD') {
          this.$store.commit('auth/SET_NEED_AUTH_FIELD', error.response.data.field_needed)
        } else if (error.response.data.error_code === 'REGISTRATION_NOT_FOUND') {
          if (payload.participant_uuid) {
            this.$router.push('/' + (this.isDev ? 'dev/' : '') + this.$data.event_identifier)
            Container.get(Notification).error('This registration id does not exist.')
            return
          }
          try {
            const response = await this.$store.dispatch('auth/registration', payload)
            const access_token = response.data.access_token
            /**
             * Keep the group parent access token for group related calls
             * Use the new access token created here for all other calls
             */
            if (Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_registration_access_token`) && Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`) && !Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_parent_access_token`)) {
              /**
               * First registration's access token should now be the group parent token, to be used in group calls
               */
              Container.get(GtrStorage).setItem(`${this.$data.event_identifier}_group_parent_access_token`, Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_registration_access_token`))
            }
            /**
             * After saving the group parent access token or just doing a regular single registration login, set the access token in local storage and for axios
             */
            Container.get(GtrStorage).setItem(`${this.$data.event_identifier}_registration_access_token`, access_token)

            this.$store.commit('auth/SET_VIEWABLE_REG_PAGES', response.data.viewable_reg_pages)
            this.$store.commit('auth/SET_CURRENT_REG_PAGES', response.data.current_reg_pages)
            if (Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`)) {
              const group_uuid = Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`)
              const data = {
                event_identifier: this.$data.event_identifier,
                group_uuid
              }
              await this.$store.dispatch('register/addToGroupRegistration', data, {root: true})
            }
            if (payload.data.registrationTypeData) {
              const registrationTypeData = payload.data.registrationTypeData

              const dataOption = {
                option_group_uuid: registrationTypeData.option_group_uuid,
                option_uuid: registrationTypeData.option_uuid,
                event_identifier: this.$data.event_identifier,
                qty: 1 //default to 1 for now
              }

              await this.$store.dispatch('options/optionChange', dataOption, {root: true})
            }
            this.$router.push('/' + (this.isDev ? 'dev/' : '') + payload.event_identifier + '/register/' + response.data.viewable_reg_pages[0])
          } catch (error) {
            Container.get(Notification).error(error.response.data.error_message)
            /**
             * If site is required, make the call to request for an invite here
             */
            if (error.response.data.error_code === 'INVITE_REQUIRED') {
              this.$store.commit('auth/SET_REQUIRE_INVITE', true)
            }
          }
        } else {
          Container.get(Notification).error('Please specify a valid key.')
        }
      } finally {
        this.$store.dispatch('common/hideLoader')
      }
    }

    this.$bus.$on('process-form', this.processForm)
    const pageNum = this.$route.params.pagenum

    if (pageNum == 'receipt') {
      this.$router.push('/' + (this.isDev ? 'dev/' : '') + this.$data.event_identifier)
    }
    try {
      this.$store.dispatch('common/showLoader', {value: true})
      this.$data.loadingInfo = true
      const formData = {
        event_identifier: this.$data.event_identifier,
        page_number: pageNum,
        language: this.$data.currentLanguage
      }
      const participantData = {
        event_identifier: this.$data.event_identifier,
        isDev: this.isDev
      }
      const response = await this.$store.dispatch('auth/getParticipant', participantData)
      this.$data.allowRegistrationCanel = response.data.participant_data.status === 'Complete'

      // store the participant data in this variable.
      const participant = response.data;

      // if there is participant AND it has a group_uuid store it for later.
      if (participant && participant.participant_group_uuid) {
        Container.get(GtrStorage).setItem(`${this.$data.event_identifier}_group_uuid`, response.data.participant_group_uuid)
      }
      await this.$store.dispatch('formbuilder/getForm', formData)
      await this.$store.dispatch('event/getEvent', {event_identifier: this.$data.event_identifier})

      /**
       * If we are on a group registration, call the getGroupFees call to show the box of group registrants on the Register.vue page
       */
      if (participant && participant.participant_group_uuid) {
        try {
          const groupData = {
            event_identifier: this.$data.event_identifier,
            group_uuid: participant.participant_group_uuid
          }
          await this.$store.dispatch('payment/getGroupFees', groupData)
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        }
      }
      if (this.isPayForAnotherPage && !this.groupNotCapped) {
        const invalid = false
        this.$data.page.pageData.fields.forEach((field: any) => {
          if (field.field === 'pay_for_another') {
            field.current_value = 'No'
          }
        })
        this.handleProcessForm(invalid)
      }
    } catch (error) {
      if (error.response.data.error_code !== 'NO_ACCESS_TO_PAGE') {
        Container.get(ErrorHandlerService).error(error)
      }
      else {
        const firstPage = error.response.data.current_reg_pages[0]
        this.$router.push('/' + (this.isDev ? 'dev/' : '') + this.$data.event_identifier + "/register/" + firstPage)
      }
    } finally {
      this.$store.dispatch('common/hideLoader')
      this.$data.loadingInfo = false
    }
  }

  //#region Watchers
  @Watch('stripe_token')
  onStripeTokenChange(payload: any) {
    if (payload) {
      this.$data._stripe_token = payload
    }
  }

  @Watch('event')
  onEventChange(payload: any) {
    if (payload) {
      const startDate = moment(payload.event_start_date).local()
      const endDate = moment(payload.event_end_date).local()
      const minutesBetweenStartAndEndDates = endDate.diff(startDate, 'minutes')
      const hoursDifference = Math.floor(minutesBetweenStartAndEndDates / 60)
      const minutesDifference = minutesBetweenStartAndEndDates % 60
      const event: any = {
        start: [startDate.year(), startDate.month() + 1, startDate.date(), startDate.hour(), startDate.minutes()],
        duration: {hours: hoursDifference, minutes: minutesDifference},
        title: payload.name,
        description: payload.name,
        location: payload.address,
        geo: {
          lat: (typeof payload.latitude === 'string') ? parseFloat(payload.latitude) : payload.latitude,
          lon: (typeof payload.longitude === 'string') ? parseFloat(payload.longitude) : payload.longitude
        }
      }

      this.$data.calendarICS = ics.createEvent(event, (error, value) => {
        if (error) {
          return null
        }
        return value
      })
    }
  }

  @Watch('payflow_token')
  onPayFlowTokenChange(payload: any) {
    if (payload) {
      this.$data._payflow_token = payload
    }
  }

  @Watch('groupFees')
  onGroupFeesChange(payload: any) {
    if (payload) {
      this.$data._groupFees = payload

      for (const index in this.$data._groupFees.group_all) {
        // set up the object.
        const uuid = this.$data._groupFees.group_all[index].uuid
        const name = this.$data._groupFees.group_participant_names[uuid]
        const line_items = this.$data._groupFees.group_participant_fees[uuid].line_items
        const parent = this.$data._groupFees.group_all[index].participant_group.parent
        this.$store.commit('payment/UPDATE_PARTICIPANT_TRACKER',
          {
            uuid: uuid, // paticipants uuid.
            name: name, // participants name.
            line_items, // add new line_items.
            parent // use the current objects parent value.
          }
        )
      }
      if (this.$data.page) {
        const parentParticipant = this.parentParticipant
        if (parentParticipant) {
            const fields = this.$data.page.pageData.fields
            for (const field in fields) {
              const curField = fields[field]
              if (this.settings.group_registration_prepoulated_fields) {
                for (const key in this.settings.group_registration_prepoulated_fields) {
                  const item = this.settings.group_registration_prepoulated_fields[key]
                  if (curField.field == item) {
                    if (this.$data.page.pageData.fields[field]['current_value'] == null || this.$data.page.pageData.fields[field]['current_value'].length == 0 ) {
                      if (item === 'country' || item === 'state' ) {
                        const country_opt_group_uuid = curField.option_group_uuid
                        for (const item in parentParticipant['selected_option_qty'][country_opt_group_uuid]) {
                          this.$data.page.pageData.fields[field]['current_value'] = item
                        }
                      } else {
                        this.$data.page.pageData.fields[field]['current_value'] = parentParticipant.participant_data[curField.field]
                      }
                    }
                  }
                }
              }
            }
        }
      }
    }
  }

  @Watch('form')
  async onFormChange(payload: any) {
    if (payload) {
      // this.$data.loadingStripe = true
      this.$data.page = payload
      this.$data._form = payload
      this.$data._viewable_reg_pages = payload.viewable_reg_pages
      /**
       * Add scripts based on payment processors
       */
      if (this.payment_processor_information && this.payment_processor_information.processor === 'authnet') {
        if (!document.querySelector('#authnet_script') && this.$data.page.pageData.paymentForm) {
          const authnet_script = document.createElement('script')
          const authnet_script_url = this.$data.page.pageData.paymentForm.processorData.endpoint
          if (authnet_script_url) {
            authnet_script.setAttribute('src', authnet_script_url)
            authnet_script.setAttribute('id', 'authnet_script')
            authnet_script.async = true
            document.head.appendChild(authnet_script)
          }
        }
      }
      if (this.payment_processor_information && this.payment_processor_information.processor === 'stripe' && this.$data.page.pageData.page_type !== 'RECEIPT') {
        for (const fieldKey in this.$data.page.pageData.fields) {
          const field = this.$data.page.pageData.fields[fieldKey]
          if (field && field.type === 'payment' && field.payment_info === undefined) {
            Vue.set(field, 'payment_info', {state: '', province: '', country: '', city: '', zip: ''})
          }
        }
      }
      /**
       * Set page to go back to
       */
      const pageToGoBackToIndex = payload.viewable_reg_pages.indexOf(this.$data.pagenum) - 1
      this.$data.pageToGoBackTo = payload.viewable_reg_pages[pageToGoBackToIndex]

      /**
       * Setup types of v-model initial values based on what Vuetify is expecting
       */
      for (let i = 0; i < this.$data.page.pageData.fields.length; i++) {
        /**
         * Make checkbox v-model into an array if it has a blank value
         */
        if (this.$data.page.pageData.fields[i].type === 'checkbox' || this.$data.page.pageData.fields[i].type === 'sessions') {
          if (
            this.$data.page.pageData.fields[i].current_value === '' ||
            this.$data.page.pageData.fields[i].current_value === false ||
            this.$data.page.pageData.fields[i].current_value === null
          ) {
            this.$data.page.pageData.fields[i].current_value = []
          }
        }
        /**
         * For date and time pickers, set up the showMenu property to show/hide the popup
         */
        if (this.$data.page.pageData.fields[i].type === 'date' || this.$data.page.pageData.fields[i].type === 'time') {
          if (
            this.$data.page.pageData.fields[i].current_value === '' ||
            this.$data.page.pageData.fields[i].current_value === false ||
            this.$data.page.pageData.fields[i].current_value === null
          ) {
            this.$data.page.pageData.fields[i].current_value = ''
            Vue.set(this.$data.page.pageData.fields[i], 'showMenu', false)
          }
        }
      }

      /**
       * If this is the receipt page, call /participant/me/registration-form again to trigger the confirmation email
       * Hide the buttons
       */
      if (this.$data.page.pageData.page_type === 'RECEIPT') {
        this.$data.showButtons = false
        /**
         * Calendar actions
         */
        this.$data.showCalendarButton = true

        /**
         * Change the url without reloading the page via vue router
         */
        window.history.pushState(null, '', '/' + (this.isDev ? 'dev/' : '') + this.$data.event_identifier + '/register/receipt')

        try {
          await this.$store.dispatch('event/sendAnalytics', {
            event_identifier: this.$data.event_identifier,
            participant_uuid: this.participant.uuid
          })
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        }

        /**
         * Clear localStorage
         */
        Container.get(GtrStorage).clear()
        localStorage.removeItem('idp_login')
        try {
          await this.$store.dispatch('event/receipt', {
            event_identifier: this.$data.event_identifier,
            pagenum: this.$data.pagenum
          })
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        }
      }
    }
  }

  @Watch('fees')
  onFeesChange(payload: any) {
    if (payload) {
      this.$data._fees = payload
    }
  }

  @Watch('viewable_reg_pages')
  onViewableRegPagesChange(payload: any) {
    if (payload) {
      this.$data._viewable_reg_pages = payload

    }
  }

  @Watch('finishedPayment')
  onFinishedPaymentChange(payload: any) {
    if (payload) {
      this.submitForm(this.$data.currentPageSubmitData)
    }
  }

  @Watch('isThisAPaymentPage')
  async onIsThisAPaymentPageChange(payload: any) {
    if (payload && this.payment_processor_information && this.payment_processor_information.processor == 'payflow') {
      const payflowData = {
        event_identifier: this.$data.event_identifier,
        pagenum: this.$data.pagenum,
        widget: this.paymentWidget
      }
      if (this.isThisAPaymentPage) {
        try {
          await this.$store.dispatch('payment/startPayflowToken', payflowData)
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        }
      }
    }
    if (payload && this.payment_processor_information && this.payment_processor_information.processor == 'stripe') {
      const stripeData = {
        event_identifier: this.$data.event_identifier,
        pagenum: this.$data.pagenum,
        widget: this.paymentWidget
      }
      if (this.isThisAPaymentPage) {

        try {
          await this.$store.dispatch('payment/startStripeToken', stripeData)
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        }
      }
    }
  }

  @Watch('participant')
  async onParticipantChange(payload: any) {
    if (payload && payload.participant_data.a2z_id) {
      this.$data.isExhibitorGroupReg = true
    }
    if (payload && payload.participant_group) {
      this.$data.isPartOfGroup = true
    }
    if (payload && payload.registration_type && payload.registration_type != this.allContent.requestedRegType) {
      try {
        await this.$store.dispatch('register/getAllContent', {
          event_identifier: this.$data.event_identifier,
          reg_type: payload.registration_type
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }
    try {
      await this.$store.dispatch('event/sendAnalytics', {
        event_identifier: this.$data.event_identifier,
        participant_uuid: payload.uuid
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('formLoaded')
  async onFormLoadedChange(payload: any) {
    if (payload) {
      if (this.pageNum !== 0 || this.$route.params.pagenum === 'receipt') {
        this.$store.commit('common/SET_PAGE', this.getPositionFromSidebarPages)
        this.$data.position = this.$store.state.common.page
      }
      /**
       * Every time a new page gets loaded, run the getFees call
       * There might be an issue where the form gets loaded before the participant, in which case the below call will fail
       * Testing has not shown this to be an issue yet
       * Participant only gets called once in App.vue
       */
      const {
        first_name,
        last_name
      } = this.participant?.participant_data

      let participant_name: string

      // TODO: refine this, believe this can be simplified.
      // if required first_name and last_name will always end up
      // getting passed in.
      if (first_name != null || last_name != null) {
        participant_name = `${first_name} ${last_name}`
      } else {
        participant_name = 'Unnamed Registration'
      }

      const participantData = {
        event_identifier: this.$data.event_identifier,
        participant_uuid: this.participant.uuid,
        participant_name: participant_name
      }

      if (this.participant.uuid) {
        try {
          await this.$store.dispatch('payment/getFees', participantData)
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        }

        if (this.participant.participant_group_uuid) {
          Container.get(GtrStorage).setItem(`${this.$data.event_identifier}_group_uuid`, this.participant.participant_group_uuid)
        }
      }
    }
  }

  @Watch('participantAndFormLoaded')
  onParticipantAndFormLoadedChange(payload: any) {
    if (payload) {
      this.$data.selected_options_printable = this.participant.selected_options_printable
      if (this.$data.page.pageData) {
        for (const option_group_uuid in this.participant.selected_options_printable) {
          this.$data.page.pageData.fields.forEach((field: any) => {
            if (field.option_group_uuid === option_group_uuid) {
              // if (field.type === 'checkbox') {
              /**
               * If it's a checkbox, create an array from the \n separated string
               * We'll collect the selections in an array
               */

              /**
               * If we get back null here, just make it an empty array
               */
              if (!payload.selected_options_printable[option_group_uuid].printable_value) {
                field.current_value = []
              } else {
                //get uuid and loop over and add it in
                for (const uuid in payload.selected_options_printable[option_group_uuid].uuid_qty) {
                  if (payload.selected_options_printable[option_group_uuid].uuid_qty[uuid] >= 1) {
                    if (Array.isArray(field.current_value)) {
                      field.current_value.push(uuid)
                    } else {
                      field.current_value = uuid
                    }
                  }
                }
              }
              // } else {
              // 	// field.current_value =
              // 	// 	newVal.selected_options_printable[option_group_uuid].printable_value
              // }
            }
          })
        }
      }
    }
  }

  showParticipantLineItemModal(id: string) {
    this.$data.participantLineItemModalsOpen[id] = true
  }

  //#endregion

  //#region Methods

  checkFileSize(field) {
    const file = field.current_value

    if (file && file.size > 2 * 1024 * 1024) {
      // File size exceeds 2MB, you can handle this case as needed
      alert('File size exceeds 2MB')
      field.current_value = null // Reset the field value or show an error message
    }
  }

  hideParticipantLineItemModal(id: string) {
    this.$data.participantLineItemModalsOpen[id] = false
  }

  eval_js(expression: any) {
    return eval(expression)
  }

  async transferReg() {
    try {
      this.$data.transferDialog = false
      await this.$store.dispatch('common/showLoader', {value: true})
      const data = {
        event_identifier: this.$data.event_identifier,
        first_name: this.$data.transferFirst,
        last_name: this.$data.transferLast,
        email: this.$data.transferEmail,
        isDev: this.isDev
      }
      await this.$store.dispatch('formbuilder/transferReg', data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  async cancelReg() {
    try {
      this.$data.cancelDialog = false
      await this.$store.dispatch('common/showLoader', {value: true})
      await this.$store.dispatch('formbuilder/cancelReg', {
        event_identifier: this.$data.event_identifier,
        isDev: this.isDev
      })
      Container.get(GtrStorage).clear()
      Container.get(Notification).success('Registration canceled')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  async applyPromoCode(field: any) {
    try {
      this.$data.promoCodeProcessDialog = true
      const data = {
        event_identifier: this.$data.event_identifier,
        participant_uuid: this.participant.uuid,
        code: field.current_value
      }
      await this.$store.dispatch('promocodes/applyPromoCode', data)
      if (Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`)) {
        const groupData = {
          event_identifier: this.$data.event_identifier,
          group_uuid: Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`)
        }
        await this.$store.dispatch('payment/getGroupFees', groupData)
      } else {

        const {
          first_name,
          last_name
        } = this.participant?.participant_data

        let participant_name: string

        // TODO: refine this, believe this can be simplified.
        // if required first_name and last_name will always end up
        // getting passed in.
        if (first_name != null || last_name != null) {
          participant_name = `${first_name} ${last_name}`
        } else {
          participant_name = 'Unnamed Registration'
        }

        const participantData = {
          event_identifier: this.$data.event_identifier,
          participant_uuid: this.participant.uuid,
          participant_name: participant_name
        }

        await this.$store.dispatch('payment/getFees', participantData)
      }
      Container.get(Notification).success('Promo code successfully applied')
    } catch (error) {
      if (error.response.status === 404) {
        Container.get(Notification).error('Promo code not found')
        return
      }
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.promoCodeProcessDialog = false
    }
  }

  // middle-man function to track radio btn, dropdown, and checkbox data
  selectOption(value: any, field: any) {
    const option = {value, field}
    const optionGroup = option.field.option_group_uuid
    const selectedOptionsArr = this.$data.selectedOptions
    let isOptionReplaced = false
    // push to selectedOptionsArr if it is empty
    if (selectedOptionsArr.length === 0) {
      selectedOptionsArr.push(option)
    }
    // loop through selectedOptionsArr
    for (let i = 0; i < selectedOptionsArr.length; i++) {
      // replace option if optionGroup is already present (i.e. change selection value)
      if (selectedOptionsArr[i].field.option_group_uuid === optionGroup) {
        selectedOptionsArr.splice(i, 1, option)
        isOptionReplaced = true
      }
    }
    // if nothing was replaced, push new option to the array
    if (isOptionReplaced === false) {
      selectedOptionsArr.push(option)
    }
    // reset
    isOptionReplaced = false
  }

  showIfChecker(field: any): boolean {
    // if field.visible is undefined
    if (field.visible === undefined) {
      // create it and set it to true.
      field.visible = true
    }

    if (field.type === 'setfield') {
      field.current_value = field.value_to_set
    }

    // if the field is a dropdown, radio, or checkbox then make the field not required and
    if (field.type == 'dropdown' || field.type == 'radio' || field.type == 'checkbox') {
      // if the associated option group is set to not visible
      if (!this.isVisible(field.option_group_uuid)) {
        // turn off field visibility
        field.visible = false
        // make it not required just in case.
        field.required = false
        // return false so it won't render.
        return false
      }
    }

    // if field visible is false
    if (field.visible === false) {
      // if it's hidden, make sure it isn't required also.
      field.required = false
      // return false so showIf will hide the field.
      return false
    }
    if (!Object.keys(field.show_if).length || !field?.show_if?.type) {
      return true
    }
    if (field.show_if.type === 'grouping') {
      const childGroupResults: any = []
      for (const parentGroupItem of field.show_if.group_items) {
        if (parentGroupItem.group_operator === 'AND') {
          childGroupResults.push(parentGroupItem.group_items.every(item => this.evaluateSingleShowIfs(item)))
        } else {
          childGroupResults.push(parentGroupItem.group_items.some(item => this.evaluateSingleShowIfs(item)))
        }
      }
      if (field.show_if.group_operator === 'AND') {
        return childGroupResults.every(result => result)
      }
      return childGroupResults.some(result => result)
    } else {
      return this.evaluateSingleShowIfs(field.show_if)
    }
  }

  evaluateSingleShowIfs(field: any): boolean {
    if (field.type === 'field_criteria') {
      const [fieldOnCurrentPage] = this.$data.page.pageData.fields.filter((page_field: any) => page_field.field === field.field)
      const current_value = fieldOnCurrentPage ? fieldOnCurrentPage.current_value : field?.current_data?.value
      if (this.$data.operatorFunctions[field.operator](current_value, field.value)) {
        return true
      }
    } else if (field.type === 'option_criteria') {
      const [fieldOnCurrentPage] = this.$data.page.pageData.fields.filter((page_field: any) => page_field.option_group_uuid === field.field)
      if (fieldOnCurrentPage) {
        if (Array.isArray(fieldOnCurrentPage.current_value)) {
          /**
           * current_value = [] means field is a checkbox
           */
          for (let i = 0; i < fieldOnCurrentPage.current_value.length; i++) {
            const fieldOnFormValue = fieldOnCurrentPage.current_value[i]
            if (this.$data.operatorFunctions[field.operator](fieldOnFormValue, field.value)) {
              return true
            }
          }
        } else {
          if (this.$data.operatorFunctions[field.operator](fieldOnCurrentPage.current_value, field.value)) {
            return true
          }
        }
      } else {
        /**
         * Show if is referencing a field on a previous page
         */
        if (!field?.current_data?.opt_group_selection_uuid_qtys) {
          return false
        }
        const {opt_group_selection_uuid_qtys: selections} = field?.current_data
        for (const show_if_name in selections) {
          if (selections[show_if_name] >= 1 && this.$data.operatorFunctions[field.operator](show_if_name, field.value)) {
            return true
          }
        }
      }
      if (fieldOnCurrentPage && fieldOnCurrentPage['field'] == 'payment_method') {
        this.$data.configureStripeCalled = false
      }
    }
    if (field.required) {
      field.required = false
    }
    return false
  }

  async goBack() {
    /**
     * Keep the language param in the router push
     */
    this.$data.loadingButtonBack = true
    while (this.$data.loading) {
      await this.sleep(1000)
    }
    let languageQueryString = ''
    if (this.$data.currentLanguage !== 'en') {
      languageQueryString = '?language=' + this.$data.currentLanguage
    }
    this.$router.push(`/${(this.isDev ? 'dev/' : '')}${this.$data.event_identifier}/register/${this.$data.pageToGoBackTo}${languageQueryString}`)
    this.$data.loadingButtonBack = false
  }

  get groupNotCapped() {
    let groupSize = 0
    if (this.$data._groupFees && this.$data._groupFees.group_all) {
      groupSize = this.$data._groupFees.group_all.length
    }
    return typeof this.settings?.group_max_participants === 'undefined' || !this.settings?.group_max_participants || groupSize<this.settings?.group_max_participants
  }

  payForAnotherOptions() {
    const options: any = []
    if (this.groupNotCapped) {
      options.push({ value: 'Yes', text: 'Yes' })
    }
    options.push({ value: 'No', text: 'No'})
    return options
  }

  /**
   * This method will compile the options for a given option group.
   * @param {string} option_group_uuid - the uuid option group to interogate
   * @param {any} field - Optional field
   * @returns the list of options or false.
   */
  setupOptions(option_group_uuid: string, field?: any) {
    // Option_groups aren't loaded, so return an empty array to this method
    if (this.option_groups.length === 0) return []

    const optionsArray: any = []
    if (field && field.field === 'pay_for_another') {
      optionsArray.push({text: 'Yes', value: 'Yes'})
      optionsArray.push({text: 'No', value: 'No'})
      return optionsArray
    }
    let option_group = this.option_groups.filter((group: any) => {
      return group.uuid === option_group_uuid
    })
    /**
     * If option_group hasn't been called yet by api, return false to prevent console error
     */
    if (option_group.length === 0) {
      return false
    }
    option_group = option_group.shift()
    if (option_group.blank_first === 1) {
      optionsArray.push({text: '', value: ''})
    }

    if (option_group.group_display) {
      field.visible = true
    } else {
      field.visible = false
    }

    const options: any = option_group.options
    // check for autohide setting. default to false.
    const autohideEnabled = this.settings?.autohide_capped_options ?? false

    const participantSelectedOptionUuids = this.participant?.selected_options?.filter(option => option.option_group.uuid === option_group_uuid)?.map(option => option.option.uuid) ?? []

    for (let i = 0; i < options.length; i++) {
      // if option is set to display false, skip it.
      const {cap, count, uuid, visible, name} = options[i]
      const capReached = (autohideEnabled && cap !== null && count >= cap)
      const isSelectedByParticipant = participantSelectedOptionUuids.includes(uuid)
      if (!visible || (capReached && !isSelectedByParticipant)) continue
      optionsArray.push({
        text: name,
        value: uuid
      })
    }
    return optionsArray
  }

  handleProcessForm(invalid: boolean, goToReceiptPage = false): void {
    this.$data.loadingButtonNext = true
    this.$data.formProcessDialog = true
    // this.$bus.$emit('process-form', { invalid, goToReceiptPage });
    this.processForm({invalid, goToReceiptPage})
  }

  handleSaveAndFinishForm(invalid: boolean, goToReceiptPage = false): void {
    this.$data.loadingButtonNext = true
    this.$data.formProcessDialog = true
    // this.$bus.$emit('process-form', { invalid, goToReceiptPage });
    this.processForm({invalid, goToReceiptPage})
  }

  // process our form page.
  async processForm(args: {
    invalid: boolean;
    goToReceiptPage: boolean;
  }): Promise<void> {
    const {
      invalid,
      goToReceiptPage
    } = args

    // this.$data.loadingButtonNext = true;
    if (invalid) {
      this.$data.loadingButtonNext = false
      this.$data.formProcessDialog = false
      return
    }
    // this.$data.loadingButtonNext = true;
    const tokenizedData: any = {
      payment_details: {}
    }

    // store the fields from the current page.
    const pageFields = this.$data.page.pageData.fields

    await this.handleFieldProcessing({pageFields, goToReceiptPage})

    // for option groups with capped limits
    const response = await this.handleBulkUpdate(this.$data.optionGroupFields)
    // if capped, this.handleBulkUpdate will throw a 422, and we want to exit out to prevent moving forward in the form
    if (response === 422) {
      this.$data.formProcessDialog = false
      return
    }

    /**
     * Process payment
     */

      // const paymentData = this.$bus.$emit('process-payment');
    const paymentField = this.$data.paymentField
    const transaction_type = this.$data.transaction_type

    if (this.isThisAPaymentPage && this.showIfChecker(paymentField)) {
      this.$data.paymentDialog = true

      /**
       * Tokenize card
       */
      //TODO figure out what to do after submitting payflow iframe
      if (this.payment_processor_information.processor === 'stripe') {
        if (paymentField.payment_type == 'vault') {
          const result = await this.$data.stripe.confirmCardSetup(this.stripe_intent, {
            payment_method: {
              card: this.$data.card,
              billing_details: {
                name:
                  paymentField.payment_info.first_name +
                  ' ' +
                  paymentField.payment_info.last_name
              }
            }
          })
          if (result.error) {
            alert(result.error.message)
          } else {
            const pm = result.setupIntent.payment_method
            this.$data.card.clear()
            tokenizedData.payment_details.stripePaymentId = pm
          }
        } else {
          const result = await this.$data.stripe.createToken(this.$data.card)
          tokenizedData.payment_details.stripeToken = result.token.id
        }
        tokenizedData.payment_details.first_name = paymentField.payment_info.first_name
        tokenizedData.payment_details.last_name = paymentField.payment_info.last_name
        tokenizedData.payment_details.address = paymentField.payment_info.address
        tokenizedData.payment_details.city = paymentField.payment_info.city
        tokenizedData.payment_details.zip = this.$data.zipCode
        tokenizedData.payment_details.country = paymentField.payment_info.country
        tokenizedData.page_number = this.$route.params.pagenum
        tokenizedData.currentLanguage = this.$data.currentLanguage
        tokenizedData.event_identifier = this.$data.event_identifier
        tokenizedData.widget_id = paymentField.widget_id
        tokenizedData.transaction_type = transaction_type

        const country = this.formatCountry(paymentField.payment_info.country)
        if (country !== 'united states') {
          delete tokenizedData.payment_details.zip
        }
        if (country === 'united states') {
          tokenizedData.payment_details.state = paymentField.payment_info.state
        } else if (country === 'canada') {
          tokenizedData.payment_details.province = paymentField.payment_info.province
        }
        await this.submitPaymentData(tokenizedData)
      }
      if (this.payment_processor_information.processor === 'authnet') {

        const secureData = {
          cardData: {
            cardNumber: paymentField.payment_info.card_number,
            month: paymentField.payment_info.expiration_month,
            year: paymentField.payment_info.expiration_year,
            cardCode: paymentField.payment_info.card_cvv
          },
          authData: {
            clientKey: this.$data.page.pageData.paymentForm.processorData.tokens.public_client_key,
            apiLoginID: this.$data.page.pageData.paymentForm.processorData.tokens.api_login_id
          }
        }

        // let authnetResults = this.$bus.$emit('process-authnet', secureData);
        // TODO: tear out below and close into a handler function.
        if ((window as any).Accept) {
          const Accept = (window as any).Accept
          Accept.dispatchData(secureData, (response: any) => {
            if (response.messages.resultCode === 'Error') {
              this.$data.paymentDialog = false
              if (response.messages.message) {
                const message = response.messages.message[0].text
                Container.get(Notification).error(`Authnet error: ${message}`)
              }
              this.$data.loadingButtonNext = false
            } else {
              tokenizedData.payment_details.dataDescriptor = response.opaqueData.dataDescriptor
              tokenizedData.payment_details.dataValue = response.opaqueData.dataValue
              tokenizedData.payment_details.expiration_month = paymentField.payment_info.expiration_month
              tokenizedData.payment_details.expiration_year = paymentField.payment_info.expiration_year
              tokenizedData.payment_details.first_name = paymentField.payment_info.first_name
              tokenizedData.payment_details.last_name = paymentField.payment_info.last_name
              tokenizedData.payment_details.address = paymentField.payment_info.address
              tokenizedData.payment_details.city = paymentField.payment_info.city
              tokenizedData.payment_details.zip = this.$data.zipCode
              tokenizedData.payment_details.country = paymentField.payment_info.country
              const country = this.formatCountry(paymentField.payment_info.country)
              if (country === 'united states') {
                tokenizedData.payment_details.state = paymentField.payment_info.state
              } else if (country === 'canada') {
                tokenizedData.payment_details.province = paymentField.payment_info.province
              }
              /**
               * Merge in authnet response
               */
              //data = Object.assign(data, tokenizedData)

              /**
               * Add everything up to this point to the payment_details property
               */
              // let dataTemp = data
              // data = {
              // 	payment_details: {},
              // }
              // for (let property in dataTemp) {
              // 	data.payment_details[property] = dataTemp[property]
              // }
              /**
               * Add non payment_details items to data
               */
              tokenizedData.page_number = this.$route.params.pagenum
              tokenizedData.currentLanguage = this.$data.currentLanguage
              tokenizedData.event_identifier = this.$data.event_identifier
              tokenizedData.widget_id = paymentField.widget_id

              tokenizedData.transaction_type = transaction_type
              this.submitPaymentData(tokenizedData)
            }
          })
        }
      }
    } else {
      /**
       * Add non payment_details items to data
       */
      // fieldValueObject.goToReceiptPage = goToReceiptPage
      this.$data.currentPageSubmitData['isDev'] = this.isDev

      // TODO change this to an emit to the event bus.

      this.submitForm(this.$data.currentPageSubmitData, goToReceiptPage)
    }
  }

  formatCountry(country: string): string {
    return (country || '').toLowerCase()
  }

  // async handleSubmitForm(data: any): Promise<any> {
  //     // TODO: put form submission behavior here.
  //     return
  // }
  //
  // async handleAuthnetDataProcessing(data: any): Promise<any> {
  //     // TODO: put authnet processing here.
  //     return
  // }
  //
  // async handleStripeDataProcessing(data: any): Promise<any> {
  //     // TODO: put stripe data processing here.
  //     return
  // }

  // TODO: break this down further into even smaller functions.
  // TODO: update the argument name.
  async handleFieldProcessing(arg: any): Promise<void> {

    const {
      pageFields,
      goToReceiptPage
    } = arg

    const eventIdentifier = this.$data.event_identifier // store the event identifier.

    // setup return objects.
    const fieldValueObject: any = {}
    const optionGroupFields: any = {
      event_identifier: eventIdentifier,
      selections: {}
    }
    let paymentField: any = {}
    let signedResponse: any = {}
    let transaction_type = ''

    // loop through pageFields argument.
    for (let i = 0; i < pageFields.length; i++) {

      // store the current field.
      const field = pageFields[i]

      // store if the field passed the showIfChecker.
      const currentlyVisible = this.showIfChecker(field)

      const notVisibleButNoShowIf = field.visible === false && (field.show_if && field.show_if.length === 0)


      // store the field value in out Object/Dictionary.
      fieldValueObject[field.field] = currentlyVisible ? field.current_value : null

      if (notVisibleButNoShowIf) {
        fieldValueObject[field.field] = field.current_value
      }

      const hasOptionGroupUuidPropertyAndNotNull = field.hasOwnProperty('option_group_uuid') && field.option_group_uuid !== null

      // Does the field have an option group uuid?
      if (hasOptionGroupUuidPropertyAndNotNull && !notVisibleButNoShowIf) {
        // setup a store for our selection.
        optionGroupFields.selections[field.option_group_uuid] = {}

        // is the current an array?
        if (Array.isArray(field.current_value)) {
          for (let i = 0; i < field.current_value.length; i++) {
            if (field.current_value[i] === '' || field.current_value[i] === null) {
              optionGroupFields.selections[field.option_group_uuid] = null
            } else {
              if (!currentlyVisible) {
                optionGroupFields.selections[field.option_group_uuid][field.current_value[i]] = {qty: 0}
              } else {
                optionGroupFields.selections[field.option_group_uuid][field.current_value[i]] = {qty: 1}
              }
            }
          }
          // if not
        } else {

          // is it empty string or null?K
          if (field.current_value === '' || field.current_value === null) {
            optionGroupFields.selections[field.option_group_uuid] = null
          } else {
            if (!currentlyVisible) {
              optionGroupFields.selections[field.option_group_uuid][field.current_value] = {qty: 0}
            } else {
              optionGroupFields.selections[field.option_group_uuid][field.current_value] = {qty: 1}
            }
          }

        }
      }

      /**
       * Check if a field with type === 'payment' exists on the page
       */
      if (field.type === 'payment') {
        // isPaymentPage = true
        paymentField = field
        transaction_type = field.payment_type
      }

      /**
       * Upload new file
       */
      if (field.type === 'file' && field.current_value instanceof File === true) {
        // This code needs to be tested
        const response: any = await Container.get(MediaService).uploadMedia(eventIdentifier, field.current_value)
        signedResponse = response.signedResponse
        fieldValueObject[field.field] = signedResponse
      }
    }

    fieldValueObject.page_number = this.$route.params.pagenum
    fieldValueObject.last_viewable_reg_page = this.last_viewable_reg_page
    fieldValueObject.goToReceiptPage = goToReceiptPage
    fieldValueObject.currentLanguage = this.$data.currentLanguage
    fieldValueObject.event_identifier = eventIdentifier

    // store our fieldObject in our component state.
    this.$data.currentPageSubmitData = fieldValueObject // can probably simplify this.
    this.$data.optionGroupFields = optionGroupFields
    this.$data.paymentField = paymentField
    this.$data.signedResponse = signedResponse
    this.$data.transaction_type = transaction_type

  }

  async handleBulkUpdate(optionGroupFields: any): Promise<any> {
    try {
      optionGroupFields.lr_orders = false
      await this.$store.dispatch('options/bulkOptionsUpdate', optionGroupFields)
    } catch (error) {
      // if response is 422 display an error notification.
      if (error.response.status === 422) {
        if (error.response.data.overcap_option?.name) {
          Container.get(Notification).error(`${error.response.data.overcap_option.name} has hit its limit`)
        } else if (error.response.data.conflicting_option?.name) {
          Container.get(Notification).error(`${error.response.data.error_message}: ${error.response.data.current_option.name} overlaps with ${error.response.data.conflicting_option.name}`)
        } else {
          Container.get(Notification).error(error.response.data.error_message)
        }
        // turn off next loading button.
        this.$data.loadingButtonNext = false

        return error.response.status
      }
    }
  }

  handleOptionPopupOpen($event: any, item: any, optionGroupUuid: string) {
    $event.stopPropagation()
    $event.preventDefault()
    const optionGroup = this.option_groups.filter((group: any) => group.uuid === optionGroupUuid).pop()
    const options = optionGroup.options
    const option = options.filter((option: any) => option.uuid === item.value).pop()
    this.$data.currentOptionPopupDetails.title = option?.popup_details?.title ?? ''
    this.$data.currentOptionPopupDetails.body = option?.popup_details?.body ?? ''
    this.$data.showOptionPopupModal = true
  }

  handleOptionPopupClose() {
    this.$data.showOptionPopupModal = false
    this.$data.currentOptionPopupDetails.title = ''
    this.$data.currentOptionPopupDetails.body = ''
  }

  showPopupDetailsOnOptionGroup(optionGroupUuid: string, item: any) {
    const optionGroup = this.option_groups.filter((group: any) => group.uuid === optionGroupUuid).pop()
    const options = optionGroup.options
    const option = options.filter((option: any) => option.uuid === item.value).pop()
    return option?.popup_details && (option.popup_details.title || option.popup_details.body)
  }

  // submitForm takes the current form page data.
  async submitForm(data: any, goToReceiptPage = false) {
    // on submit try the following.
    try {
      // If there are no more participants to add, set the access token back to the group parent's token.
      if (data.pay_for_another === 'No') {
        const parent_token = Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_parent_access_token`)
        if (parent_token) {
          Container.get(GtrStorage).setItem(`${this.$data.event_identifier}_registration_access_token`, parent_token)
        }
      }
      // as long as loading is true, wait 5 ms.
      while (this.$data.loading) {
        await this.sleep(500)
      }
      // dispatch the submitForm action on the formBuilder store.
      const result = await this.$store.dispatch('formbuilder/submitForm', data)
      if (goToReceiptPage) {
        let previousIndex = 0
        for (let i = 0; i < result.data.viewable_reg_pages.length; i++) {
          if (result.data.viewable_reg_pages[i] === 'receipt') {
            this.$router.push('/' + this.$data.event_identifier + '/register/' + result.data.viewable_reg_pages[previousIndex])
          }
          previousIndex = i
        }
      }

      // if pay_for_another is 'Yes'...
      if (data.pay_for_another === 'Yes') {
        // if a user decides to pay for another person.
        // set the page back to one, to enter the email of the next person.
        this.$store.commit('common/SET_PAGE', 1)
      }
      // } else if (!data.pay_for_another && this.isNextPagePayment()) {
      //     // if pay_for_anoter is falsy and the next page is the payment page.
      //     // store our current line items.
      //     this.$store.commit('payment/SET_GROUP_STORAGE', items);
      // }

    } catch (error) {
      // display errors on failure.
      Container.get(ErrorHandlerService).error(error)
    } finally {
      // regardless of success or failure
      // toggle off the loading button, payment dialog and form process dialog.
      this.$data.loadingButtonNext = false
      this.$data.paymentDialog = false
      this.$data.formProcessDialog = false
      if (this.$data.card) {
        this.$data.card.clear()
      }
    }
  }

  // submit payment to backend.
  async submitPaymentData(data: any) {
    try {
      // add our line items to our pay data.
      data.line_items = this.trackerLineItems
      await this.$store.dispatch('formbuilder/submitPayment', data)
    } catch (error) {
      this.$data.loadingButtonNext = false
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.paymentDialog = false
      this.$data.formProcessDialog = false
    }
  }

  downloadCalendarFile() {
    window.open('data:text/calendar;charset=utf8,' + escape(this.$data.calendarICS), '_blank')
  }

  async navigateToEditRegistration(eventIdentifier: string, loginKey: string) {
    this.$router.push({
      name: 'registration.edit-registration',
      params: {'event_identifier': eventIdentifier, 'login_key': loginKey}
    })
  }

  // computed property that returns all line items

  getColumnsByFieldType(type: string) {
    if (type === 'text' || type === 'dropdown' || type === 'date' || type === 'time' || type === 'promocode')
      return 6
    else if (type === 'textarea' || type === 'radio' || type === 'checkbox' || type === 'html' || type === 'payment' || type === 'file')
      return 12
  }

  isReadOnlyField(field: any) {
    if (field.read_only_if_complete) {
      const completed = this.$store.state.auth.participant.participant_data.status === 'Complete' ? true : false
      if (completed) {
        return true
      } else {
        return false
      }
    }
    if (field.read_only_if_set) {
      if (field.current_value) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  replaceMask(mask: string) {
    if (['numericMask', 'alphabeticMask'].includes(mask)) {
        return ''
    } else if (mask) {
      return mask.replace(/#/g, ' _')
    }
    return mask
  }

  private isVisible(uuid: string): boolean {
    const groups = this.option_groups
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      if (group.uuid === uuid) {
        return group.group_display
      }
    }
    return true
  }

  // this method will check the next page in allFormData to see if its the payment page.
  private isNextPagePayment(): boolean {
    const currentPage = this.$data.pagenum
    const nextPage = this.$data._form.allFormData[currentPage + 1]

    if (nextPage.fields[0].type === 'payment') {
      return true
    }

    return false
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  private getMask(mask: string, currentValue: string) {
    if (mask === 'alphabeticMask') {
      return (currentValue || '').split('').map((_) => /[A-Za-z]/)
    }
    if (mask === 'numericMask') {
      return (currentValue || '').split('').map((_) => /[0-9]/)
    }
    return mask
  }

  get incompleteGroupMembers () {
    return (this.isPayForAnotherPage || this.isThisAPaymentPage) && this.isGroup && !this.$data._groupFees.save_and_finish_enabled
  }
  //#endregion
}
